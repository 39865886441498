import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { style } from "style";
import AnalyticsPageView from "../AnalyticsPageView";

const TitlePageComponent = ({ title }) => {
  const titleMap = {
    PetroRio: "PRIO",
    Proauto: "Garagem",
    "Cheirin Bao": "Cheirin Bão",
    clubecasa: "Club&Casa",
    ClubCasa: "Club&Casa Integra",
    "Grupo Renascenca": "Grupo Renascença",
  };

  const defaultTitle = titleMap[style.names.nameApp] || style.names.nameApp;

  return (
    <>
      <Helmet>
        <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      </Helmet>
      <AnalyticsPageView title={title} />
    </>
  );
};

TitlePageComponent.propTypes = {
  title: PropTypes.string,
};

TitlePageComponent.defaultProps = {
  title: "",
};

export default TitlePageComponent;
